import React from 'react';
import { graphql } from 'gatsby';

import HomeLayout from 'layouts/HomeLayout';
import { Features, Form, HowToPlay, SEO, UpcomingMatches } from 'components';

const IndexPage = ({ data }) => {
  const {
    contentfulSiteMetadata,
    contentfulHero,
    contentfulForm,
    allContentfulInstruction,
    allContentfulFeatures,
    allContentfulMatch,
  } = data;

  return (
    <HomeLayout
      socialsData={contentfulSiteMetadata}
      heroData={contentfulHero}
      isFourOhFour={false}
    >
      <SEO />
      <HowToPlay {...allContentfulInstruction} />
      <UpcomingMatches {...allContentfulMatch} />
      <Features {...allContentfulFeatures} />
      <Form {...contentfulForm} />
    </HomeLayout>
  );
};

export const query = graphql`
  query {
    contentfulSiteMetadata {
      instagram
      tikTok
      twitter
      footerText
      buttonText
      disclaimer
    }
    contentfulHero {
      header
      subheader
      image {
        file {
          url
        }
      }
    }
    contentfulForm {
      header
      body {
        body
      }
      includeEmail
      includeFirstName
      includeLastName
      errorMessage
      successMessage
    }
    allContentfulInstruction(
      sort: { order: ASC, fields: orderNumber }
      limit: 6
    ) {
      edges {
        node {
          orderNumber
          text
          image {
            file {
              url
            }
          }
        }
      }
    }
    allContentfulFeatures(limit: 2) {
      edges {
        node {
          heading
          image {
            file {
              url
            }
          }
          body {
            raw
          }
        }
      }
    }
    allContentfulMatch(sort: { order: ASC, fields: datetime }) {
      edges {
        node {
          awayTeam {
            clubName
            clubBadge {
              file {
                url
              }
            }
          }
          datetime
          homeTeam {
            clubName
            clubBadge {
              file {
                url
              }
            }
          }
        }
      }
    }
  }
`;

export default IndexPage;
