import React from 'react';

import { Header, Hero, MobileNav, Footer } from 'components';

import { Container, Content, ChildrenWrap } from './styled';

const MainLayout = ({ socialsData, heroData, children, isFourOhFour }) => {
  console.log(!isFourOhFour);
  return (
    <Container id='top' isFourOhFour={isFourOhFour}>
      {socialsData && <Header socialsData={socialsData} />}
      {socialsData && <MobileNav socialsData={socialsData} />}
      {heroData && (
        <Hero heroData={heroData} buttonText={socialsData.buttonText} />
      )}
      <Content isFourOhFour={isFourOhFour}>
        <ChildrenWrap>{children}</ChildrenWrap>
        {socialsData && !isFourOhFour && <Footer socialsData={socialsData} />}
      </Content>
    </Container>
  );
};

export default MainLayout;
